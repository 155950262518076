//google fonts
@import url('https://fonts.googleapis.com/css?family=Anton|Inconsolata|Major+Mono+Display');

//color variables
$white: #fff; 
$bright-blue: #0099ff; 
$light-black: #111; 
$lime: #aacc00; 

//font variables
$major-mono-display: 'Major Mono Display'; 
$inconsolata: 'Inconsolata'; 
$anton: 'Anton'; 

//color configs
$main-background: $white; 
$nav-background: $light-black; 
$navlink-text: $white; 
$content-background: $white;  
$navlink-active-background: $bright-blue; 

//font configs
$main-header: $major-mono-display; 

body {
  background-color: $main-background;  
  font-family: $main-header, sans-serif;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: $main-header, sans-serif;
}
a {
  text-decoration: none; 
  color: $light-black; 
} 
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: $nav-background;
  padding: 0;
}
ul.header li a {
  color: $navlink-text;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: $content-background;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.centered {
  text-align: center; 
} 
.active {
  background-color: $navlink-active-background;
}
.social-icon {
  height: 21px; 
  width: 21px; 
  vertical-align: middle; 
  padding-left: 5px; 
} 
.social-icons {
  padding: 0px; 
} 
.d-inline {
  display: inline; 
  list-style-type: none; 
  padding: 0px; 
  margin: 0px; 
} 
.right-side-links {
  position: absolute; 
  top: 20px; 
  right: 20px; 
  list-style-type: none; 
} 
.link {
  text-decoration: none; 
  color: $light-black; 
} 
.post {
  margin: 20px; 
  border: 1px dotted $light-black; 
  border-radius: 10px; 
  padding: 20px; 
} 
.post-link {
  margin: 10 10 0 0; 
  display: inline-block; 
  border-radius: 5px; 
  padding: 10px; 
  border: 1px dotted $light-black; 
}
 
#post-link-dark {
  background-color: $light-black; 
  color: $white; 
  a {
    color: $white; 
  } 
} 
.blog-text {
  text-align: center; 
} 

//tictactoe stuff 
.tic-tac-toe {
  padding: 20px; 
  visibility: hidden; 
} 
.status {
  padding: 20px; 
} 
.row {
  margin: auto; 
} 
.column {
  display: inline-block; 
} 
.cell {
  border: 2px solid black; 
  width: 40px; 
  height: 40px; 
  text-align: center; 
  cursor: pointer; 
} 

//overlap logo section
.overlap-div {
  padding: 10px; 
  height: 75px; 
  border: 2px solid black; 
}  
.overlap {
  position: absolute;  
  font-size: 75px; 
  font-family: $anton, sans-serif;
} 
#overlap-1 {
  color: black; 
  z-index: 2; 
} 
#overlap-2 {
  color: blue; 
  z-index: 1; 
  opacity: .8; 
}
#overlap-3 {
  color: yellow; 
  z-index: 3; 
  opacity: .8; 
}

#music-gallery {
  margin-top: 20px; 
  margin-bottom: 20px; 
} 

//about
#about-photo {
  margin: auto; 
  display: block; 
  border-radius: 50%; 
  height: 200; 
  width: 200; 
} 
#about-content {
  width: 65%; 
  margin: auto; 
}  
.spotify-player {
  margin: 20px; 
  box-shadow: 5px 5px 5px; 
}  
.music-section {
  margin-bottom: 50; 
}  
 
