@import url(https://fonts.googleapis.com/css?family=Anton|Inconsolata|Major+Mono+Display);
body {
  background-color: #fff;
  font-family: "Major Mono Display", sans-serif;
  padding: 20px;
  margin: 0; }

h1, h2, p, ul, li {
  font-family: "Major Mono Display", sans-serif; }

a {
  text-decoration: none;
  color: #111; }

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0; }

ul.header {
  background-color: #111;
  padding: 0; }

ul.header li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block; }

.content {
  background-color: #fff; }

.content h2 {
  padding: 0;
  margin: 0; }

.content li {
  margin-bottom: 10px; }

.centered {
  text-align: center; }

.active {
  background-color: #0099ff; }

.social-icon {
  height: 21px;
  width: 21px;
  vertical-align: middle;
  padding-left: 5px; }

.social-icons {
  padding: 0px; }

.d-inline {
  display: inline;
  list-style-type: none;
  padding: 0px;
  margin: 0px; }

.right-side-links {
  position: absolute;
  top: 20px;
  right: 20px;
  list-style-type: none; }

.link {
  text-decoration: none;
  color: #111; }

.post {
  margin: 20px;
  border: 1px dotted #111;
  border-radius: 10px;
  padding: 20px; }

.post-link {
  margin: 10 10 0 0;
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  border: 1px dotted #111; }

#post-link-dark {
  background-color: #111;
  color: #fff; }
  #post-link-dark a {
    color: #fff; }

.blog-text {
  text-align: center; }

.tic-tac-toe {
  padding: 20px;
  visibility: hidden; }

.status {
  padding: 20px; }

.row {
  margin: auto; }

.column {
  display: inline-block; }

.cell {
  border: 2px solid black;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer; }

.overlap-div {
  padding: 10px;
  height: 75px;
  border: 2px solid black; }

.overlap {
  position: absolute;
  font-size: 75px;
  font-family: "Anton", sans-serif; }

#overlap-1 {
  color: black;
  z-index: 2; }

#overlap-2 {
  color: blue;
  z-index: 1;
  opacity: .8; }

#overlap-3 {
  color: yellow;
  z-index: 3;
  opacity: .8; }

#music-gallery {
  margin-top: 20px;
  margin-bottom: 20px; }

#about-photo {
  margin: auto;
  display: block;
  border-radius: 50%;
  height: 200;
  width: 200; }

#about-content {
  width: 65%;
  margin: auto; }

.spotify-player {
  margin: 20px;
  box-shadow: 5px 5px 5px; }

.music-section {
  margin-bottom: 50; }

